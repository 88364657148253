/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 確保頁面布局支持 Footer 正確位置 */
html, body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.main-content {
  flex-grow: 1; /* 填充剩餘空間，確保 Footer 位於底部 */
}

footer {
  background-color: #878787; /* Footer 底色 */
  color: white; /* 白色文字 */
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  position: relative; /* 防止與其他內容重疊 */
}
